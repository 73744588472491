
/**
 * @name useDeviceView
 * @description 判斷目前的畫面尺寸
 * @example
 * 
 *  <template>
 *    <!-- This component will mount only when mobile size screen -->
 *    <MobileMenu v-if="deviceView.mobile" />
 *  </template>
 * 
 *  <script lang="ts" setup>
 *     const deviceView = useDeviceView()
 *  </script>
 * 
 *   OR
 * 
 *  <script lang="ts">
 *    export default defineComponent({
 *      data(){
 *        return {
 *          deviceView: useDeviceView()
 *        }
 *      }
 *    },
 *  </script> 
 * 
 */


import UAParser from 'ua-parser-js'

const MB_WIDTH = 420
const PC_WIDTH = 1280

const breakpoints = [
  { name: 'xs', max: 615 },
  { name: 'sm', min: 616, max: 975 },
  { name: 'md', min: 976, max: 1279 },
  { name: 'lg', min: 1280, max: 1919 },
  { name: 'xl', min: 1920 },
  { name: 'mobile', max: 975 }, // 二分法：手機
  { name: 'desktop', min: 976 }, // 二分法：電腦
  { name: 'tablet', min: 976, max: 1279 }, // 三分法平板
]
interface DeviceView {
  width: number
  [viewport: string]: boolean | number,
}

function initDeviceView(mobile: boolean = false) {

  const defaultWidth = mobile ? MB_WIDTH : PC_WIDTH
  const deviceView = reactive<DeviceView>({
    width: (process.client ? window.innerWidth : defaultWidth)
  })

  function setDefault(name: string, min: number | undefined, max: number | undefined) {
    const width = (process.client ? window.innerWidth : defaultWidth) // 依據目前環境使用初始寬度
    if (min && max) {
      deviceView[name] = (width >= min && width < max)
    } else if (max) {
      deviceView[name] = (width < max)
    } else if (min) {
      deviceView[name] = (width >= min)
    }
  }

  function defineRule(name: string, expression: string) {
    if (!process.client) {
      return
    }

    const matcher = window.matchMedia(expression)
    deviceView[name] = matcher.matches
    // deviceView._inspect[name] = expression
    try {
      // Chrome & Firefox
      matcher.addEventListener('change', ({ matches }) => {
        deviceView.width = window.innerWidth
        deviceView[name] = matches
      })
    } catch (error) {
      // Safari 14
      matcher.addListener(({ matches }) => {
        deviceView[name] = matches
      })
    }
  }

  breakpoints.forEach(({ name, min, max }) => {
    const eMin = min ? `(min-width: ${min}px)` : ''
    const eMax = max ? `(max-width: ${max}px)` : ''
    defineRule(name, ['all', eMin, eMax].filter(exist => exist).join(' and '))
    setDefault(name, min, max)
    if (eMin) defineRule(`>=${min}`, `all and ${eMin}`)
    if (eMin) defineRule(`>=${name}`, `all and ${eMin}`)
    if (eMax) defineRule(`<${name}`, `all and ${eMax}`)
  })

  return deviceView
}

export const useDeviceView = (): DeviceView => {

  const deviceType = UAParser(useRequestHeaders()['user-agent']).device.type
  let deviceView: DeviceView

  if (!useNuxtApp().vueApp.config.globalProperties?.deviceView) {

    if ([UAParser.DEVICE.MOBILE, UAParser.DEVICE.TABLET].includes(deviceType)) {
      deviceView = initDeviceView(true)
    } else {
      deviceView = initDeviceView()
    }

    useNuxtApp().vueApp.config.globalProperties.deviceView = deviceView

  }

  return useNuxtApp().vueApp.config.globalProperties.deviceView
}
